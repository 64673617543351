import React from 'react';
import TextPhotoSlides from './common/TextPhotoSlides';
import { Box } from '@mui/material';

const Review = () => {
  const menteesExperience = [
    {
      items: [
        {
          title: 'Prasanna',
          description:
            'I built my confidence by giving seminars and also we have a friendly faculty for guiding me in a proper way to built my career. Thanks to the TechiesEdge for supporting me.',
          imageUrl: 'assets/images/girl.png',
        },
      ],
    },
    {
      items: [
        {
          title: 'S. Ammi Reddy',
          description:
            'Before I have a stage fear by session of daily speech make me loss of stage fear. Before I dont have a skills to write a single line of code but now I have improved my coding skills. I improved my Typing speed also by this program.',
          imageUrl: 'assets/images/boy.png',
        },
      ],
    },
    {
      items: [
        {
          title: 'Bhavana',
          description:
            'I improved a lot in both coding and communication skills compared to previous years by participating in activities like seminars, group discussions. My way of thinking regading life and career has changes.',
          imageUrl: 'assets/images/girl.png',
        },
      ],
    },
    {
      items: [
        {
          title: 'Swetha',
          description:
            'Mentors make me free of issues that I am facing in coding and communication skills. That follow up everyday and check the tasks, correct my mistakes. Now I improved in coding and communication skills.',
          imageUrl: 'assets/images/girl.png',
        },
      ],
    },
    {
      items: [
        {
          title: 'M. Hemanth Kumar',
          description:
            'I improved my programming skill a lot here and got rid of my stage fear with the help of activities like presentation, group-discussion and JAM.',
          imageUrl: 'assets/images/girl.png',
        },
      ],
    },
    {
      items: [
        {
          title: 'Sunitha',
          description:
            'I have learnt programming skils besides improving my communication skills. Before I have a stage fear, buy now I can able to deliver speech confidently. Thanks to the mentors for their support and coordination.',
          imageUrl: 'assets/images/girl.png',
        },
      ],
    },
  ];
  return (
    <Box component="section" pt={6}>
      <TextPhotoSlides cards={menteesExperience} />
    </Box>
  );
};

export default Review;
