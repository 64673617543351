import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import CounterCard from 'src/components/counterCard';

function Counters() {
  return (
    <Box component="section" py={10}>
      <Grid container display={'flex'} justifyContent={'center'} style={{ marginTop: '1px solid #ccc' }}>
        <Grid item xs={12} md={3}>
          <CounterCard count={1429} suffix="+" title="Professionals Trained" />
        </Grid>
        <Grid item xs={12} md={3} borderLeft="2px dotted #e0e0e0">
          <CounterCard count={25} title="IT Companies tied up with us" />
        </Grid>
        <Grid item xs={12} md={3} borderLeft="2px dotted #e0e0e0">
          <CounterCard count={30} title="Interviews every week" />
        </Grid>
        <Grid item xs={12} md={3} borderLeft="2px dotted #e0e0e0">
          <CounterCard count={21} title="Cadidates placed last week" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Counters;
