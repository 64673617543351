import React from 'react';
import PhotoSlides from './common/PhotoSlides';
import { Box } from '@mui/material';

const ImageSection = () => {
  const image = [
    {
      imageUrl: '/assets/images/Picture-1.jpg',
    },
    {
      imageUrl: 'assets/images/Picture-2.jpg',
    },
    {
      imageUrl: 'assets/images/Picture-3.jpg',
    },
  ];
  return (
    <Box component="section" pt={6}>
      <PhotoSlides cards={image} />
    </Box>
  );
};

export default ImageSection;
