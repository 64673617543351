import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Presentation from './components';

export default function App() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Presentation />
    </ThemeProvider>
  );
}
