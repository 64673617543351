import React, { useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Avatar, Box, Container, Grid, Typography } from '@mui/material';

const TextPhotoSlides = ({ interval = 8000, animation = 'fade', cards }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      setIsMobile(isMobile);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [cards.length, interval]);

  return (
    <Box
      component="section"
      variant="gradient"
      position="relative"
      pt={4}
      mt={4}
      sx={{ background: '#f5f5f5', minHeight: isMobile ? '600px' : '400px' }}
    >
      <Grid item xs={12} marginBottom={'20px'}>
        <Typography variant="h3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          Mentee's Experience
        </Typography>
      </Grid>
      <Container maxWidth={'lg'}>
        <Carousel
          index={activeIndex}
          autoPlay={true}
          interval={null}
          animation={animation}
          onChange={handleSlideChange}
        >
          {cards?.map((card, index) => {
            return card?.items?.map((item) => (
              <Grid container key={index} rowSpacing={2}>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <Avatar alt="logo" src={item.imageUrl} sx={{ width: 100, height: 100 }} />
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                  <Typography style={{ color: 'orange', fontWeight: 'bold' }}>{item.title}</Typography>
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                  <Typography>{item.description}</Typography>
                </Grid>
              </Grid>
            ));
          })}
        </Carousel>
      </Container>
    </Box>
  );
};

export default TextPhotoSlides;
