import React, { useEffect, useRef, useState } from 'react';
import './header.css';
import { Box, Drawer, Grid, Typography } from '@mui/material';
import Iconify from '../common/iconify/Iconify';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';

const Header = () => {
  const headerMenuList = [
    { name: 'Home', url: '#home', style: 'nav-link', target: '' },
    { name: 'Courses', url: '#courses', style: 'nav-link', target: '' },
    { name: 'Contact us', url: '#contact', style: 'nav-link', target: '' },
    {
      name: 'Register',
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSdFiuSCRTyv5MoS4n7X2cj8VorzR-3NlMk0vrzXQ6C2T75h3A/viewform?pli=1',
      style: 'button-link',
      target: 'blank',
    },
  ];

  const [isMobile, setIsMobile] = useState(false);
  const [isDrawer, setIsDrawer] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      setIsMobile(isMobile);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClickDrawer = () => {
    setIsDrawer(!isDrawer);
  };

  const onClickList = () => {
    setIsDrawer(!isDrawer);
  };

  return (
    <header className="headerStyles">
      <Grid container display={'flex'} alignItems={'center'}>
        <Grid item container xs={10} lg={2}>
          <img src="assets/images/make.png" alt="logo" width="80%" />
        </Grid>
        <Grid xs={1} lg={10}>
          {!isMobile ? (
            <nav className="navStyles">
              <ul className="navListStyles">
                <li className="navItemStyles">
                  <a href="#home" className="nav-link">
                    Home
                  </a>
                </li>
                <li className="navItemStyles">
                  <a href="#courses" className="nav-link">
                    Courses
                  </a>
                </li>
                <li className="navItemStyles">
                  <a href="#contact" className="nav-link">
                    Contact Us
                  </a>
                </li>
                <li className="navItemStyles">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdFiuSCRTyv5MoS4n7X2cj8VorzR-3NlMk0vrzXQ6C2T75h3A/viewform?pli=1"
                    target="blank"
                    className="button-link"
                    type="button"
                  >
                    Register
                  </a>
                </li>
              </ul>
            </nav>
          ) : (
            <>
              <Iconify icon="lucide:menu" width={30} style={{ color: 'black' }} onClick={() => onClickDrawer()} />
              <Drawer anchor={'top'} open={isDrawer}>
                <Box role="presentation">
                  <Grid container padding={'10px 15px'}>
                    <Grid item container xs={11}>
                      <img src="assets/images/make.png" alt="TE-logo" width="auto" />
                    </Grid>
                    <Grid item xs={1}>
                      <Iconify
                        icon="lucide:menu"
                        width={30}
                        style={{ color: 'black' }}
                        onClick={() => onClickDrawer()}
                      />
                    </Grid>
                  </Grid>
                  <List>
                    <nav>
                      {headerMenuList.map((list) => (
                        <>
                          <ListItem key={list.name} disablePadding>
                            <ListItemButton onClick={() => onClickList()}>
                              <a href={list.url} className={list?.style} target={list?.target}>
                                {list.name}
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                    </nav>
                  </List>
                </Box>
              </Drawer>
            </>
          )}
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
