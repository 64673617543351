import React, { useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Card, CardMedia } from '@mui/material';

const PhotoSlides = ({ interval = 5000, animation = 'slide', cards = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [cards.length, interval]);

  return (
    <Carousel index={activeIndex} autoPlay={true} interval={null} animation={animation} onChange={handleSlideChange}>
      {cards.map((card, index) => (
        <Card key={index}>
          <CardMedia component="img" height={'450px'} width={'100%'} image={card.imageUrl} alt={card.title} />
        </Card>
      ))}
    </Carousel>
  );
};

export default PhotoSlides;
