import React from 'react';
import { Box } from '@mui/material';

const WorkFlow = () => {
  return (
    <Box component="section">
      <img src={'/assets/images/workflow.jpg'} alt={'logo'} width="100%" />
    </Box>
  );
};

export default WorkFlow;
