import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

const CompanySection = () => {
  const companyLogos = [
    'assets/images/cognizant.png',
    'assets/images/emmes.png',
    'assets/images/technotree.png',
    'assets/images/provise.png',
    'assets/images/triecoders.png',
    'assets/images/wipro.png',
    'assets/images/happiestminds.png',
    'assets/images/softvision.png',
  ];

  return (
    <Box component="section" pt={6} px={1} background={'#f5f5f5'}>
      <Container maxWidth={'lg'}>
        <Grid container rowSpacing={5}>
          <Grid item xs={12}>
            <Typography variant="h3" textAlign={'center'}>
              Companies Partnered
            </Typography>
          </Grid>
          <Grid item container columnSpacing={6}>
            {companyLogos.map((company) => (
              <Grid item xs={6} lg={3}>
                <img src={company} alt={'logo'} width={'100%'} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanySection;
