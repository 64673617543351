import { Typography } from '@mui/material';
import Iconify from './common/iconify/Iconify';

const date = new Date().getFullYear();

const FooterData = {
  brand: {
    image: 'assets/icons/navbar/techies_edge_logo.png',
    height: 45,
    width: 230,
  },
  socials: [
    {
      icon: <Iconify icon="logos:facebook" />,
      link: 'https://www.google.com',
    },
    {
      icon: <Iconify icon="skill-icons:instagram" />,
      link: 'https://www.google.com',
    },
    {
      icon: <Iconify icon="ri:twitter-x-fill" />,
      link: 'https://www.google.com',
    },
  ],
  menus: [
    {
      name: 'BANGALORE',
      items: [
        { name: '51/8, 1st Floor, 14th Main,' },
        { name: 'Shubh Enclave 3rd Gate, ' },
        { name: 'Harlur Road, Near Bellandur Gate,' },
        { name: 'Karnataka - 560102.' },
      ],
    },
    {
      name: 'RAJAMAHENDRAVARAM',
      items: [
        { name: 'D No: 86-07-16/2, Tilak Road,' },
        { name: 'Beside Sai Baba Temple,' },
        { name: 'Gandhipuram, Arkaya Nivas,' },
        { name: 'A.P. - 533106' },
      ],
    },
  ],
  copyright: (
    <Typography style={{ color: 'white', fontSize: '12px' }}>
      All rights reserved. Copyright &copy; {date} Techies Edge .
    </Typography>
  ),
};

export default FooterData;
