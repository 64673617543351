import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import Iconify from './common/iconify/Iconify';

function Footer({ content }) {
  const { brand, menus, copyright } = content;

  return (
    <Box component="footer" sx={{ background: 'black' }} px={2}>
      <Grid container rowSpacing={2}>
        <Grid item container xs={12} md={4} rowSpacing={2}>
          <Grid item container xs={12}>
            <img src="assets/images/make-footer.png" alt="TE-logo" width="40%" />
          </Grid>

          <Grid item xs={12}>
            <Typography
              display="block"
              variant="button"
              color={'#359bf0'}
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              Contact Us
            </Typography>
            <Typography
              display="block"
              variant="button"
              fontWeight="regular"
              mb={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Iconify icon="mi:call" style={{ color: 'white' }} />
              <span style={{ marginLeft: '10px', color: 'white' }}> +91 7989711903</span>
            </Typography>
            <Typography display="block" fontWeight="regular" mb={1} style={{ display: 'flex', alignItems: 'center' }}>
              <Iconify icon="quill:mail" style={{ color: 'white' }} />
              <span style={{ marginLeft: '10px', color: 'white', fontSize: '16px' }}>contactus@techiesedge.com</span>
            </Typography>
          </Grid>
        </Grid>
        {menus?.map(({ name: title, items }) => (
          <Grid key={title} item xs={12} md={4}>
            <Typography
              display="block"
              fontSize={'16px'}
              color={'#359bf0'}
              fontWeight="bold"
              textTransform="capitalize"
              mb={1}
            >
              {title}
            </Typography>
            <Box component="ul">
              {items?.map(({ name }) => (
                <Typography fontSize={'14px'} textTransform="capitalize" style={{ color: 'white' }}>
                  {name}
                </Typography>
              ))}
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} sx={{ textAlign: 'center', my: 3, color: 'white' }}>
          {copyright}
        </Grid>
      </Grid>
    </Box>
  );
}

Footer.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default Footer;
