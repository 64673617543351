import React from 'react';
import { Box } from '@mui/material';

const AboutMentoring = () => {
  return (
    <Box component="section" pt={6}>
      <img src="assets/images/mentoring.jpg" alt="logo" width="100%" />
    </Box>
  );
};

export default AboutMentoring;
