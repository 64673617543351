import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import Iconify from './iconify/Iconify';

function Card({ image, name, count, pro, icon, ...rest }) {
  return (
    <Grid
      bgColor="white"
      borderRadius="10px"
      border={'1px solid #ccc'}
      minHeight="10rem"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        '&:hover': {
          transform: 'perspective(999px) rotateX(7deg) translate3d(0px, -5px, 5px)',
        },
      }}
      container
      display={'flex'}
      justifyContent={'center'}
      {...rest}
    >
      <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Iconify icon={icon} width={70} />
      </Grid>
      <Grid item xs={11} textAlign={'center'}>
        <Typography variant="h4" color="black">
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
}

Card.defaultProps = {
  name: '',
  count: 0,
  pro: false,
};

Card.propTypes = {
  image: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string,
  count: PropTypes.number,
  pro: PropTypes.bool,
};

export default Card;
