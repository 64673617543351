import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import Card from './common/card';

function DesignBlocks() {
  const data = [
    {
      title: 'Programming Languages',
      courses: [
        {
          icon: 'devicon:c',
          name: 'Basic Programming',
        },
        {
          icon: 'devicon:java',
          name: 'Java',
        },
        {
          icon: 'devicon:python',
          name: 'Python',
        },
      ],
    },
    {
      title: 'UI/UX',
      courses: [
        {
          icon: 'devicon:angularjs',
          name: 'Angular JS',
        },
        {
          icon: 'vscode-icons:file-type-reactjs',
          name: 'React JS',
        },
      ],
    },
    {
      title: 'Devops',
      courses: [
        {
          icon: 'logos:aws',
          name: 'AWS',
        },
        {
          icon: 'logos:microsoft-azure',
          name: 'Microsoft Azure',
        },
      ],
    },
    {
      title: 'Testing',
      courses: [
        {
          icon: 'carbon:workflow-automation',
          name: 'Selinium',
        },
        {
          icon: 'carbon:workflow-automation',
          name: '(Python) Manual and Automation Testing',
        },
      ],
    },
  ];

  return (
    <Box component="section" pt={6}>
      <Grid container spacing={3} display={'flex'}>
        <Grid item xs={12} lg={3}>
          <Typography variant="h3">Courses Gallery</Typography>
        </Grid>
        <Grid item container xs={12} lg={9} rowSpacing={2}>
          {data.map((list) => (
            <Grid item container rowSpacing={4} borderBottom={'1px solid #ccc'}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ color: 'black' }}>
                  {list?.title}{' '}
                </Typography>
              </Grid>
              <Grid item container spacing={3}>
                {list?.courses?.map(({ image, name, count, icon, pro }) => (
                  <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
                    <Card image={image} name={name} count={count} icon={icon} pro={pro} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default DesignBlocks;
