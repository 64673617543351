import Card from '@mui/material/Card';
import { Grid } from '@mui/material';
import Header from './header';
import AboutMentoring from './Mentoring';
import DesignBlocks from './DesignBlocks';
import CompanySection from './CompanySection';
import Counters from './Counter';
import FooterData from './FooterData';
import Footer from './Footer';
import WorkFlow from './WorkFlow';
import Review from './Review';
import ImageSection from './ImageSection';

function Presentation() {
  return (
    <>
      <section id="home" style={{ paddingTop: '40px', marginTop: '-40px' }}>
        <Header />
      </section>
      <Grid container marginTop="100px">
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <WorkFlow />
          <AboutMentoring />
          <section id="courses" style={{ paddingTop: '40px', marginTop: '-40px' }}>
            <DesignBlocks />
          </section>
          <ImageSection />
          <CompanySection />
          <Review />
          <Counters />
        </Card>
      </Grid>
      <section id="contact" style={{ paddingTop: '40px', marginTop: '-40px' }}>
        <Footer content={FooterData} />
      </section>
    </>
  );
}

export default Presentation;
